import React from 'react'
import { TopCard } from './TopCard'
import { Routes } from './Routes'
import { BrowserRouter as Router} from "react-router-dom"
import { Auth0LoginWall } from './Auth0LoginWall'
import { NotificationDisplay } from './NotificationDisplay'
import { ErrorBoundary } from './ErrorBoundary'

export class App extends React.Component {
  render() {

    return (
      <ErrorBoundary>
        <Router>
          <NotificationDisplay>
            <Auth0LoginWall>
              <TopCard />
              <Routes />
            </Auth0LoginWall>
          </NotificationDisplay>
        </Router>
      </ErrorBoundary>
    )
  }
}
