import React, { useState, useContext } from 'react';
import { Text, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { UserContext } from './contexts/UserContext';
import { API } from './Config';

export function FetchingButton(props) {
  return (
    <PrimaryButton onClick={props.onClick} styles={props.styles} disabled={props.isFetching || props.disabled}>
      {props.isFetching ? <Spinner size={SpinnerSize.small} /> : <Text>{props.text}</Text>}
    </PrimaryButton>
  );
}

export function APIFetchingButton(props) {
  const [isFetching, setIsFetching] = useState(false);
  const user = useContext(UserContext);
  /**
   * I removed the AbortController for now
   * because I'm not sure if it's a good
   * to abort running requests if user navigates
   * aways from the page this button is on
   */
  // const abortController = new AbortController()
  // const abortSignal = abortController.signal

  // useEffect(() => {
  //   return function abortRequest() {
  //     abortController.abort()
  //   }
  // })

  const submitAPIRequest = async () => {
    const data = props.onGetData();
    const route = props.onGetRoute();
    const { getAuthToken } = user;
    const authToken = await getAuthToken();
    const opts = {
      // signal: abortSignal,
      method: props.method ?? 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    setIsFetching(true);
    try {
      const res = await fetch(`${API.url}/${route}`, opts);
      if (res.status && res.status === 200) {
        let resData = null;
        try {
          resData = await res.json();
        } catch (err) {
          /**
           * no response data but that's
           * ok as some routes just dont
           * return anything
           */
        }
        setIsFetching(false);
        props.onSuccess(resData);
      } else {
        let error = null;
        try {
          /**
           * try to get an error message from
           * the response of our failed request
           */
          const errorRes = await res.json();
          error = errorRes.error ?? errorRes;
        } catch (err) {
          /**
           * could not parse response the response
           */
        }
        props.onError({ status: res.status, statusText: res.statusText, error });
        setIsFetching(false);
      }
    } catch (err) {
      setIsFetching(false);
      props.onError({ error: err, status: null, statusText: null });
    }
  };

  return <FetchingButton text={props.text} styles={props.styles} disabled={props.disabled} onClick={submitAPIRequest} isFetching={isFetching} />;
}
