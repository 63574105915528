import React, { useContext } from 'react'
import { getTheme, CommandBarButton } from '@fluentui/react'
import { NotificationContext } from './contexts/NotificationContext'

export function WaffleButton(props) {
  const theme = getTheme()
  const topBarButtonStyles = {
    root: {
      width: 'calc(3rem + 8px)', height: '3rem', backgroundColor: theme.palette.themePrimary,
      color: theme.semanticColors.primaryButtonText
    },
    rootHovered: { backgroundColor: theme.palette.themeSecondary },
    rootChecked: { backgroundColor: theme.palette.themeSecondary },
    rootCheckedHovered: { backgroundColor: theme.palette.themeSecondary },
    rootCheckedPressed: { backgroundColor: theme.palette.themeSecondary },
    rootExpanded: { backgroundColor: theme.palette.themeSecondary },
    rootFocused: { backgroundColor: theme.palette.themeSecondary },
    rootExpandedHovered: { backgroundColor: theme.palette.themeSecondary },
    rootPressed: { backgroundColor: theme.palette.themeSecondary },
    icon: { color: theme.semanticColors.primaryButtonText },
    iconHovered: { color: theme.semanticColors.primaryButtonText },
    iconChecked: { color: theme.semanticColors.primaryButtonText },
    iconCheckedHovered: { color: theme.semanticColors.primaryButtonText },
    iconCheckedPressed: { color: theme.semanticColors.primaryButtonText },
    iconExpanded: { color: theme.semanticColors.primaryButtonText },
    iconFocused: { color: theme.semanticColors.primaryButtonText },
    iconExpandedHovered: { color: theme.semanticColors.primaryButtonText },
    iconPressed: { color: theme.semanticColors.primaryButtonText }
  }

  const notifications = useContext(NotificationContext)
  
  const notificationTest = () => {
    notifications.error({message: 'Error: This is not an actual error but a test.'})
    notifications.error({message: 'Error: This is not an actual error but a test. This one has a timeout.', timeout: 5000})
    notifications.success({message: 'Everything is fine. Yay.'})
    notifications.warn({message: 'Uh oh. There is something fishy. Not really but here is a button.', actions: [{onClick: () => { alert('Hello, alert!') }, label: 'Alert!'}]})
    notifications.info({message: 'I am an info. Look how boring and gray I am.'})
  }

  return (
    <CommandBarButton styles={topBarButtonStyles}
      iconProps={{ iconName: 'WaffleOffice365' }}
      onClick={notificationTest}
    />
  )
}