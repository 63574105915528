import * as React from 'react';
import { withAPIConnection } from './containers/withAPIConnection';
import { Text, getTheme } from '@fluentui/react';
import { ProductDetailsHeader } from './ProductDetailsHeader';
import { Switch, Route } from 'react-router-dom';
import { ProductEditFormWithAPIConnection } from './ProductEditForm';

export function ProductDetails(props) {
  const theme = getTheme();
  /**
   * product is feteched from the API
   * while productId is availbale immediately
   * when this component mounts
   */
  const { product, productId } = props;

  const styles = {
    marginTop: '1rem',
  };

  const headingStyle = {
    marginLeft: '1.25rem',
    color: theme.semanticColors.bodyText,
  };

  const bodyStyles = {
    backgroundColor: theme.semanticColors.bodyBackground,
    border: `1px solid ${theme.semanticColors.menuDivider}`,
    margin: '.5rem 1.25rem .5rem .5rem',
  };

  return (
    <div style={styles}>
      <Text style={headingStyle} variant={'xLarge'}>
        {product ? product.sku : 'No product found'}
      </Text>
      <div style={bodyStyles}>
        {product && <ProductDetailsHeader product={product} />}
        <Switch>
          <Route path="/protected/products/:productId/edit">
            <ProductEditFormWithAPIConnection productId={productId} onProductModified={props.onProductModified} onProductDeleted={props.onProductDeleted} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export const ProductDetailsWithAPIConnection = withAPIConnection(
  // wrapped component
  ProductDetails,

  // initial data
  {
    product: null,
  },

  // get route and params callback
  (state, props) => ({
    route: `products/${props.productId}`,
  }),

  //select data callback
  (data, state, props) => ({
    product: data,
  }),

  // should refetch callback
  (prevState, state, prevProps, props) => prevProps.productId !== props.productId || props.productsModifiedOn !== prevProps.productsModifiedOn
);
