import * as React from 'react';
import { CommandBar } from '@fluentui/react';

export function ProductsCommandBar(props) {
  const items = React.useMemo(
    () => [
      {
        key: 'newProduct',
        text: 'New Product',
        iconProps: { iconName: 'Add' },
        onClick: props.onNewClicked,
      },
    ],
    [props.onNewClicked]
  );

  return <CommandBar items={items} ariaLabel="Use left and right arrow keys to navigate between commands" />;
}
