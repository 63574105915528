import * as React from 'react'
import { Switch, Route } from "react-router-dom"
import { Products } from './Products'
import { getTheme } from '@fluentui/react'
import { Callback } from './Callback'

export class Routes extends React.Component {
  render() {

    const theme = getTheme()

    const styles = {
      backgroundColor: theme.semanticColors.bodyBackground,
      position: 'absolute',
      left: '0',
      top: '3rem',
      minHeight: 'calc(100vh - 3rem)'
    }

    return (
      <div style={styles}>
        <Switch>
          <Route path="/login/callback">
            <Callback />
          </Route>
          <Route path="/protected/products/:productId">
            <Products />
          </Route>
          <Route path="/protected/products">
            <Products />
          </Route>
          <Route path="/">
            <Products />
          </Route>
        </Switch>
      </div>
    )
  }
}