import React from 'react'
import { TextField } from '@fluentui/react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      hasError: false,
      error: null
    }

    this.styles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }

    this.headingStyles = {

    }

    this.stackStyles = {
      root: {
        width: '65rem'
      }
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    console.error(errorInfo)
  }

  render() {

    if (this.state.hasError) {
      return (
        <div style={this.styles}>
          <h1 style={this.headingStyles}>meltdown :(</h1>
          {this.state.error && this.state.error.stack && 
            <div>
              <TextField 
                styles={this.stackStyles}
                multiline 
                rows={50} 
                disabled 
                defaultValue={this.state.error.stack.toString()} 
              />
            </div>
          }
        </div>
      )
    }

    return this.props.children 
  }
}