import * as React from 'react';
import { getTheme } from '@fluentui/react';
import { useHistory, useParams, Switch, Route } from 'react-router-dom';
import { ProductCreationForm } from './ProductCreationForm';
import { ProductListWithAPIConnection } from './ProductList';
import { ProductDetailsWithAPIConnection } from './ProductDetails';
import { ProductsCommandBar } from './ProductsCommandBar';

export function Products(props) {
  const theme = getTheme();
  const history = useHistory();
  const { productId } = useParams();
  const [productsModifiedOn, setProductsModifiedOn] = React.useState(null);

  const styles = {
    height: 'calc(100vh - 3rem)',
  };

  const bodyStyles = {
    display: 'flex',
    flexWrap: 'no-wrap',
  };

  const rightSideStyles = {
    flex: '1 1 100%',
    borderLeft: `1px solid ${theme.semanticColors.bodyFrameDivider}`,
    paddingLeft: '.5rem',
    color: theme.semanticColors.bodyText,
  };

  const handleNewProductClicked = () => {
    history.push(`/protected/products/new`);
  };

  const handleNewProductCreated = product => {
    setProductsModifiedOn(Date.now());
    history.push(`/protected/products/${product.id}`);
  };

  const handleProductModified = product => {
    setProductsModifiedOn(Date.now());
    // product = null;
  };
  const handleProductDeleted = product => {
    console.warn('###################### handleProductDeleted');
    setProductsModifiedOn(Date.now());
    console.warn('###################### product: ' + JSON.stringify(product));
    product = null;
  };

  return (
    <div style={styles}>
      <ProductsCommandBar onNewClicked={handleNewProductClicked} />
      <div style={bodyStyles}>
        <ProductListWithAPIConnection productsModifiedOn={productsModifiedOn} />
        <div style={rightSideStyles}>
          <Switch>
            <Route path="/protected/products/new">
              <ProductCreationForm onProductCreated={handleNewProductCreated} />
            </Route>
            <Route path="/protected/products/:productId">
              <ProductDetailsWithAPIConnection
                productId={productId}
                onProductModified={handleProductModified}
                productsModifiedOn={productsModifiedOn}
                onProductDeleted={handleProductDeleted}
              />
            </Route>
            <Route path="/protected/products">
              <></>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
