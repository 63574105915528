import * as React from 'react'
import { NotificationContext } from '../contexts/NotificationContext'

/**
 * Wrap a component so it has access to the notification functions `success`, `error`, `info` and `warn`
 * 
 * Notifications are displayed by invoking one of the functions beforementioned functions passing it an
 * 
 * object of the following shape for configuration:
 * ```
 * {
 *  message: string, // the message to show
 *  timeout?: number | 'no' // optional timeout in ms after the notification will be dismissed automatically
 *  actions?: [{
 *    label: string, 
 *    onClick: Function
 *  }] // optional action button to show along with the message
 * }
 * ```
 * By default notifications with action buttons, warnings and error will not time out while infos and warnings
 * 
 * will be dismissed after 3000 ms
 * @param {*} WrappedComponent    The component that should receive the capability to send notifications
 * 
 */
export function withNotificationContext(WrappedComponent) {

  return class extends React.Component {
    render() {
      return (
        <NotificationContext.Consumer>
          {
            (notifications) => (<WrappedComponent {...this.props} notifications={notifications}/>)
          }
        </NotificationContext.Consumer>
      )
    }
  }

}