/**
 * Variables that initialised from process.env.* are
 * defined in .env[.*] in the project root.
 * The values are replaced during build time.
 * For more information on how to set and override varibales see
 * https://create-react-app.dev/docs/adding-custom-environment-variables/
 *
 * DO NOT PUT SECRETS HERE AS THEY WILL LAND IN THE BUNDLE
 */

// No trailing slash!
const API = {
  baseUrl: process.env.REACT_APP_API_URL,
  url: `${process.env.REACT_APP_API_URL}/api/v2/protected`,
  versionUrl: `${process.env.REACT_APP_API_URL}/api/v0/public/getVersion`,
};

const Auth = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  logoutReturnUri: process.env.REACT_APP_AUTH_LOGOUT_RETURN_URI,
};

export { API, Auth };
