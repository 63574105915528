import * as React from 'react';
import { Text, getTheme, Callout, Persona, PersonaSize, PersonaInitialsColor, CommandBarButton, ActionButton } from '@fluentui/react';
import { FontWeights, Depths } from '@fluentui/theme';
import { UserContext } from './contexts/UserContext';
import { WaffleButton } from './WaffleButton';
import { API } from './Config';

const theme = getTheme();

const uiVersion = require('../package.json').version

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const cardStyles = {
  backgroundColor: theme.palette.themePrimary,
  boxShadow: Depths.depth8,
  width: '100%',
  height: '3rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  zIndex: 999,
};

const titleStyles = {
  color: theme.semanticColors.primaryButtonText,
  fontSize: theme.fonts.large,
  fontWeight: FontWeights.semibold,
  padding: '0 12px 1px 8px',
  display: 'flex',
  alignItems: 'center',
};

const topBarButtonStyles = {
  root: {
    width: 'calc(3rem + 8px)',
    height: '3rem',
    backgroundColor: theme.palette.themePrimary,
    color: theme.semanticColors.primaryButtonText,
  },
  rootHovered: { backgroundColor: theme.palette.themeSecondary },
  rootChecked: { backgroundColor: theme.palette.themeSecondary },
  rootCheckedHovered: { backgroundColor: theme.palette.themeSecondary },
  rootCheckedPressed: { backgroundColor: theme.palette.themeSecondary },
  rootExpanded: { backgroundColor: theme.palette.themeSecondary },
  rootFocused: { backgroundColor: theme.palette.themeSecondary },
  rootExpandedHovered: { backgroundColor: theme.palette.themeSecondary },
  rootPressed: { backgroundColor: theme.palette.themeSecondary },
  icon: { color: theme.semanticColors.primaryButtonText },
  iconHovered: { color: theme.semanticColors.primaryButtonText },
  iconChecked: { color: theme.semanticColors.primaryButtonText },
  iconCheckedHovered: { color: theme.semanticColors.primaryButtonText },
  iconCheckedPressed: { color: theme.semanticColors.primaryButtonText },
  iconExpanded: { color: theme.semanticColors.primaryButtonText },
  iconFocused: { color: theme.semanticColors.primaryButtonText },
  iconExpandedHovered: { color: theme.semanticColors.primaryButtonText },
  iconPressed: { color: theme.semanticColors.primaryButtonText },
};

const personaStyles = {
  details: {
    display: 'none',
  },
};

const calloutStyles = {
  padding: '.5rem 2rem 2rem 2rem',
  display: 'flex',
  flexWrap: 'wrap',
};

const calloutHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: '0 0 1rem 0',
};

const calloutBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
};

const calloutBodyPersonaStyles = {
  root: {
    flex: '0 0 33%',
    paddingRight: '2rem',
  },
};

const calloutBodyTextWrapStyles = {
  display: 'flex',
  flex: '0 0 66%',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const versionStyles = {
  fontWeight: FontWeights.light,
};

export class TopCard extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.personaRef = React.createRef();

    this.state = {
      isCalloutVisible: false,
      apiVersion: `no version info`,
    };
  }

  componentDidMount() {
    this._getVersionInfo();
  }

  async _getVersionInfo() {
    try {
      const opts = {
        method: 'get',
        headers: {
          Accept: 'application/json',
        },
      };
      const url4Version = API.versionUrl;
      const res = await fetch(url4Version, opts);
      if (res.status && res.status === 200) {
        try {
          const versionInfo = await res.json();
          this.setState({ apiVersion: versionInfo.api });
        } catch (err) {
          console.error(`Could not parse api version: ${err}`);
        }
      }
    } catch (error) {
      console.error(`Could not get api version: ${error}`);
    }
  }

  render() {
    const { user } = this.context;
    const initials = user.name.substr(0, 2).toUpperCase();

    return (
      <div style={cardStyles}>
        <div style={{ display: 'flex' }}>
          <div>
            <WaffleButton />
          </div>
          <div style={titleStyles}>{`Products`}</div>
        </div>
        <div>
          <div style={versionStyles}>{`ui: ${uiVersion}, api: ${this.state.apiVersion}`}</div>
        </div>
        <div>
          <CommandBarButton styles={topBarButtonStyles} onClick={this._onPersonaButtonClick.bind(this)}>
            <Persona imageInitials={initials} size={PersonaSize.size32} initialsColor={PersonaInitialsColor.gray} styles={personaStyles} />
            <div ref={this.personaRef}></div>
          </CommandBarButton>
          {this.state.isCalloutVisible && (
            <Callout onDismiss={this._toggleIsCalloutVisible.bind(this)} target={this.personaRef.current} gapSpace={16} calloutMaxWidth={400}>
              <div style={calloutStyles}>
                <div style={calloutHeaderStyles}>
                  <Text>Account</Text>
                  <ActionButton text={'Sign out'} onClick={this.context.logout} />
                </div>
                <div style={calloutBodyStyles}>
                  <Persona imageInitials={initials} size={PersonaSize.size72} initialsColor={PersonaInitialsColor.gray} styles={calloutBodyPersonaStyles} />
                  <div style={calloutBodyTextWrapStyles}>
                    <Text variant={'xLarge'}>{capitalizeFirstLetter(this.context.user.nickname)}</Text>
                    <Text>{this.context.user.name}</Text>
                  </div>
                </div>
              </div>
            </Callout>
          )}
        </div>
      </div>
    );
  }

  _onPersonaButtonClick() {
    this._toggleIsCalloutVisible();
  }

  _toggleIsCalloutVisible() {
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
  }
}
