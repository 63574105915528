import * as React from 'react';
import { withAPIConnection } from './containers/withAPIConnection';
import { useHistory, useParams } from 'react-router-dom';
import { Nav } from '@fluentui/react';

export function ProductList(props) {
  const history = useHistory();
  const { productId } = useParams();

  const styles = {
    root: {
      width: '18rem',
      height: 0, // no matter, just be here
      boxSizing: 'border-box',
      border: '1px solid #eee',
      overflowY: 'auto',
      minHeight: `calc(100vh - 3rem - 2.8125rem)`,
    },
    linkText: {
      fontSize: '13px',
    },
    navItem: {
      borderBottom: '1px solid rgb(243, 242, 241)',
      ':hover': {
        borderBottom: '1px solid rgb(255, 255, 255)',
      },
    },
    link: {
      color: 'rgb(96, 94, 92)',
      ':hover': {
        color: 'rgb(50, 49, 48)',
      },
    },
  };

  const navLinks = [
    {
      links: props.products.map(product => ({
        name: product.sku,
        key: `${product.id}`,
      })),
    },
  ];

  const onLinkClick = (_, link) => {
    history.push(`/protected/products/${link.key}`);
  };

  return <Nav onLinkClick={onLinkClick} selectedKey={productId} ariaLabel="Product navigation" styles={styles} groups={navLinks} />;
}

export const ProductListWithAPIConnection = withAPIConnection(
  // wrapped component
  ProductList,

  // initial data
  {
    products: [],
  },

  // get route and params callback
  (state, props) => ({
    route: 'products',
  }),

  //select data callback
  (data, state, props) => ({
    products: data,
  }),

  // should refetch callback
  (prevState, state, prevProps, props) => props.productsModifiedOn !== prevProps.productsModifiedOn
);
