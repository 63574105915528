import * as React from 'react';
import { Text, getTheme, Persona, PersonaSize, PersonaInitialsColor, FontSizes } from '@fluentui/react';
import { ProductDetailsCommandBar } from './ProductDetailsCommandBar';

export function ProductDetailsHeader(props) {
  const { product } = props;
  const theme = getTheme();
  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.semanticColors.bodyText,
    padding: '.75rem',
  };
  const leftSideStyles = {
    display: 'flex',
  };
  const personaStyles = {
    root: {
      flex: '0 0 auto',
      paddingRight: '3rem',
    },
    details: {
      padding: 0,
    },
  };
  const dateStyles = {
    color: theme.semanticColors.disabledText,
    fontSize: FontSizes.small,
  };
  const commandBarStyles = {};
  const listSubStyles = {
    fontSize: FontSizes.small,
    // padding: '.75rem',
    // not working:
    // paddingTop: '.25em'
  };
  const listSmallStyles = {
    fontSize: FontSizes.small,
  };
  const paddingTopStyles = {
    paddingTop: '.5em',
  };
  const initials = product.sku.substr(0, 2).toUpperCase();
  const dateTimeOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <div style={styles}>
      <div style={leftSideStyles}>
        <Persona imageInitials={initials} size={PersonaSize.size72} initialsColor={PersonaInitialsColor.gray} styles={personaStyles} />
        <div>
          {/* variant={"xLarge"} */}
          <Text>Name: {product.name}</Text>
          <br />
          <Text>Series: {product.series}</Text>
          <br />
          <div style={paddingTopStyles}></div>
          <Text>Tagline: {product.tagLine}</Text>
          <div style={paddingTopStyles}></div>
          {/* how to do this: color: {product.hidden ? 'red' : 'false'} */}
          <Text style={({ color: 'red' }, listSubStyles)}>hidden: {product.hidden ? 'true' : 'false'}</Text>
          <br />
          <Text style={listSubStyles}>Channel Restriction: {product.channel}</Text>
          <br />
          <Text style={listSubStyles}>sort: {product.sortOrder}</Text>
          <br />
          <Text style={listSubStyles}>free: {product.free ? 'true' : 'false'}</Text>
          <br />
          <Text style={listSubStyles}>Trial Days: {product.trialDays}</Text>
          <br />
          <Text style={listSubStyles}>featured: {product.featured ? 'true' : 'false'}</Text>
          <br />
          <Text style={listSubStyles}>featured sort: {product.featuredSortOrder}</Text>
          <div style={paddingTopStyles}></div>
          <Text style={listSmallStyles}>Dependency: {product.dependency}</Text>
          <br />
          <Text style={listSmallStyles}>Install Directory: {product.installDir}</Text>
          <br />
          <Text style={listSubStyles}>AppVersion min: {product.appVersionMin}</Text>
          <br />
          <Text style={listSubStyles}>AppVersion max: {product.appVersionMax}</Text>
          <br />
          <Text style={listSubStyles}>Last free Version (frame): {product.lastFreeVersion}</Text>
          <br />
          <div style={paddingTopStyles}></div>
          <Text style={listSmallStyles}>Image: &nbsp;</Text>
          <a style={listSmallStyles} href={product.imageUrl} target="_blank" rel="noopener noreferrer">
            {product.imageUrl}
          </a>
          <br />
          <Text style={listSmallStyles}>Moodshot: &nbsp;</Text>
          <a style={listSmallStyles} href={product.moodshotUrl} target="_blank" rel="noopener noreferrer">
            {product.moodshotUrl}
          </a>
          <br />
          <Text style={listSmallStyles}>Logo: &nbsp;</Text>
          <a style={listSmallStyles} href={product.logoUrl} target="_blank" rel="noopener noreferrer">
            {product.logoUrl}
          </a>
          <br />
          <Text style={listSmallStyles}>Manual: &nbsp;</Text>
          <a style={listSmallStyles} href={product.manualUrl} target="_blank" rel="noopener noreferrer">
            {product.manualUrl}
          </a>
          <br />
          <Text style={listSmallStyles}>Details: &nbsp;</Text>
          <a style={listSmallStyles} href={product.detailsUrl} target="_blank" rel="noopener noreferrer">
            {product.detailsUrl}
          </a>
          <br />
          <div style={paddingTopStyles}>
            <span style={dateStyles}>Created at {new Date(product.createdAt).toLocaleDateString('en-GB', dateTimeOptions)}</span>
          </div>
          <div>
            <span style={dateStyles}>Updated at {new Date(product.updatedAt).toLocaleDateString('en-GB', dateTimeOptions)}</span>
          </div>
        </div>
      </div>
      <div style={commandBarStyles}>
        <ProductDetailsCommandBar product={product} />
      </div>
    </div>
  );
}
