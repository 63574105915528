import React, { useEffect } from 'react'
import { UserContext } from './contexts/UserContext'
import { Text, Spinner, SpinnerSize } from '@fluentui/react'
import { useAuth0, Auth0Provider } from "@auth0/auth0-react"
import { Auth } from './Config'
import { useLocation } from 'react-router-dom'

function AutoRedirect(props) {
  const { logout, getAccessTokenSilently, user, isAuthenticated, loginWithRedirect, error, isLoading } = useAuth0()
  const location = useLocation()

  const spinnerStyles = {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center'
  }

  useEffect( () => {
    if (!isLoading && !isAuthenticated && !error) {
      try {
        const currentLocation = location.pathname ?? '/'
        localStorage.setItem('preLoginLocation', currentLocation)
      } catch (err) {
        /**
         * dont care
         */
      }
      loginWithRedirect()
    }
  })

  if (isAuthenticated) {
    const handleLogout = () => {
      logout({
        client_id: Auth.clientId,
        returnTo: Auth.logoutReturnUri
      })
    }
    return (
      <UserContext.Provider value={{
        email: user.name,
        user,
        error,
        logout: handleLogout,
        getAuthToken: () => { return getAccessTokenSilently() }
      }}>
        {props.children}
      </UserContext.Provider>
    )
  } else if (error) {
    return (<Text>{error.toString()}{error.message}</Text>)
  } else if (isLoading) {
    return (
      <div style={spinnerStyles}>
        <Spinner size={SpinnerSize.large} label={'Loading'}/>
      </div>
    )
  } else {
    return (
      <div style={spinnerStyles}>
        <Spinner size={SpinnerSize.large} label={'Loading'}/>
      </div>
    )
  }

}

export function Auth0LoginWall(props) {
  return (
    <Auth0Provider
      domain={Auth.domain}
      clientId={Auth.clientId}
      redirectUri={Auth.redirectUri}
      audience={Auth.audience}
    >
      <AutoRedirect>
        {props.children}
      </AutoRedirect>
    </Auth0Provider>
  )
}