import * as React from 'react'
import { Spinner, SpinnerSize } from '@fluentui/react'

export function LoadingOverlay(props) {
  const overlayStyles = {
    position: 'fixed',
    zIndex: '999',
    top: 'calc(100vh - 5rem)',
    left: 'calc(100vw - 5rem)',
    width: '5rem',
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <div style={overlayStyles}>
      <Spinner size={SpinnerSize.large} />
    </div>
  )
}