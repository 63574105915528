import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CommandBar } from '@fluentui/react';

export function ProductDetailsCommandBar(props) {
  const history = useHistory();

  const items = React.useMemo(() => [], []);

  const farItems = React.useMemo(
    () => [
      // items to the far right (only "Edit Product" still there for now)
      {
        key: 'editProduct',
        text: 'Edit Product',
        iconProps: { iconName: 'Edit' },
        onClick: () => {
          history.push(`/protected/products/${props.product.id}/edit`);
        },
      },
    ],
    [history, props.product.id]
  );

  return <CommandBar items={items} farItems={farItems} ariaLabel="Use left and right arrow keys to navigate between commands" />;
}
