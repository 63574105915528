import * as React from 'react'
import { API } from '../Config'
import { LoadingOverlay } from '../LoadingOverlay'
import { withUserContext } from './withUserContext'
import { withNotificationContext } from './withNotificationContext'

/**
 * Wrap a component so it can conveniently use the API.
 * @param {*} WrappedComponent    The component that should receive the API data.
 * 
 * @param {*} initialData         The data that the wrapping component should pass to the wrapped
 *                                component initially.
 *
 * @param {*} getRouteAndParams   A callback that should return the API route to 
 *                                request and a params object that will be POSTed. 
 *                                If null is returned instead of an object, a GET
 *                                request will be issued instead.
 * 
 * @param {*} selectData          A callback that will be called once the API request succeeded.
 *                                It receives the API response as an argument and should return an 
 *                                object with the response data formatted in the way the 
 *                                WrappedComponent expects.
 *
 * @param {*} shouldReFetch       A callback that will be called if the wrapping component updated
 *                                to determine if a the API should be queried again.
 */
export function withAPIConnection(WrappedComponent, initialData, getRouteAndParams, selectData, shouldReFetch) {

  return withNotificationContext(withUserContext(class extends React.Component {
    constructor(props) {
      super(props)

      this.getRouteAndParams = getRouteAndParams
      this.selectData = selectData
      this.shouldReFetch = shouldReFetch
      this.abortController = new AbortController()
      this.abortSignal = this.abortController.signal

      this.state = {
        isFetching: false,
        hasAPIError: false,
        data: {
          ...initialData
        }
      }
    }

    componentDidMount() {
      this._fetch()
    }

    componentWillUnmount() {
      this.abortController.abort()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.shouldReFetch(prevState, this.state, prevProps, this.props)) {
        this._fetch()
      }
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.state.data} {...this.props} isFetching={this.state.isFetching} hasAPIError={this.state.hasAPIError}/>
          {this.state.isFetching ? <LoadingOverlay /> : null}
        </React.Fragment>
      )
    }

    async _fetch() {
      const { route, params } = this.getRouteAndParams(this.state.data, this.props)
      const { getAuthToken } = this.props.user
      const authToken = await getAuthToken()
      const url = `${API.url}/${route}`
      const options = {
        signal: this.abortSignal,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        }
      }
      if (params && params !== null) {
        options.method = 'POST'
        Object.assign(options.headers, {
          'Content-Type': 'application/json',
        })
        options.body = JSON.stringify(params)
      }
      this.setState({isFetching: true})

      try {
        const res = await fetch(url, options)
        if (res.status === 200) {
          const data = await res.json()
          const selectedData = this.selectData(data, this.state.data, this.props)
          this.setState({
            isFetching: false,
            hasAPIError: false,
            data: {
              ...selectedData
            }
          })
        } else {
          /**
           * The API request returned with a status coder other than 200
           * indicating an error.
           */
          let error = null
          try {
            /**
             * Try to read an error message from the API response
             */
            const errorRes = await res.json()
            error = new Error(`API request failed. ${errorRes.error ?? errorRes}`)
          } catch (err) {
            /**
             * Reading the error message from the API response failed.
             * Go with a the general error message
             */
            error = new Error(`API request failed with status ${res.status} (${res.statusText})`)
          } finally {
            /**
             * Re-throw the error passing it to the outer try/catch
             */
            throw error
          }
        }
      } catch (err) {
        if (err.code === DOMException.ABORT_ERR) {
          /**
           * the request was aborted, 
           * likely because the user navigated away from a route
           * this is okay and doesnt need to be handled
           */
          return
        }
        this.setState({
          isFetching: false,
          hasAPIError: true,
        })
        const { notifications } = this.props
        notifications.error({
          message: err.toString()
        })
      }
    }

  }))


}