import * as React from 'react'
import { UserContext } from '../contexts/UserContext'

/**
 * Wrap a component so it has access to the currently logged in user credentials
 * @param {*} WrappedComponent    The component that should receive the credentials
 * 
 */
export function withUserContext(WrappedComponent) {

  return class extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {
            (user) => (<WrappedComponent {...this.props} user={user}/>)
          }
        </UserContext.Consumer>
      )
    }
  }

}