import * as React from 'react'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { useHistory } from 'react-router-dom'
export function Callback(props) {
  const history = useHistory()
  React.useEffect( () => {
    try {
      const preLoginLocation = localStorage.getItem('preLoginLocation')
      if (preLoginLocation && preLoginLocation.length > 0) {
        history.push(preLoginLocation)
      }
    } catch (err) {
      history.push('/')
    }
  })
  const spinnerStyles = {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <div style={spinnerStyles}>
      <Spinner size={SpinnerSize.large} label={'Loading'}/>
    </div>
  )
}