import * as React from 'react'
import { getTheme, ProgressIndicator, MessageBarButton, MessageBar, MessageBarType, Text, Stack, StackItem } from '@fluentui/react'
import { NotificationContext } from './contexts/NotificationContext'
import { v4 as UUID } from 'uuid'


const NOTIFICATION_TIMER_INTERVAL = 150

function colorForMessageBarType(type) {
  const theme = getTheme()

  if (type === MessageBarType.success) {
    return theme.semanticColors.successIcon
  } else if (type === MessageBarType.warning) {
    return theme.semanticColors.warningIcon
  } else if (type === MessageBarType.info) {
    return theme.semanticColors.infoIcon
  } else if (type === MessageBarType.error) {
    return theme.semanticColors.errorIcon
  }
  return theme.palette.themePrimary
}

function maybeGetActions(actions) {
  if (actions) {
    return (
      <>
        {
          actions.map( ({onClick, label}, ix) => (
            <StackItem>
              <MessageBarButton onClick={onClick} key={`messageBarButton-${ix}`}>
                {label}
              </MessageBarButton>
            </StackItem>
          ))
        }
      </>
    )
  }
}

function ProgressBar(props) {
  const { percentComplete, type } = props
  const styles = {
    itemProgress: {
      paddingTop: 0
    },
    progressBar: {
      backgroundColor: colorForMessageBarType(type)
    }
  }
  return (
    <ProgressIndicator 
      percentComplete={percentComplete} 
      styles={styles}
    />
  )
}

function NotificationBar(props) {
  const { notification } = props
  const { type, message, actions, timeout, progress } = notification
  const actionContent = maybeGetActions(actions)
  const presentingProgressBar = Number.isFinite(timeout)

  const styles = {

  }

  const onDismiss = () => {
    props.onDismiss(notification)
  }

  return (
    <div style={styles}>
      <MessageBar
        onDismiss={onDismiss}
        dismissButtonAriaLabel="Close"
        messageBarType={type}
        actions={actionContent}
        onMouseEnter={props.onMouseEnter}
        onMouseOut={props.onMouseOut}
        onClick={props.onClick}
      >
        <Text>{message}</Text>
      </MessageBar>
      {presentingProgressBar && 
        <ProgressBar 
          percentComplete={progress / timeout} 
          type={type}
        />
      }
    </div>
  )
}

export class NotificationDisplay extends React.Component {
  static contextType = NotificationContext
  
  constructor(props) {
    super(props)

    this.notificationTimer = null

    this.state = {
      info: this._showInfo.bind(this),
      error: this._showError.bind(this),
      warn: this._showWarning.bind(this),
      success: this._showSuccess.bind(this),
      notifications: []
    }
  }

  render() {

    const styles = {
      position: 'fixed',
      top: '4rem',
      width: '30rem',
      left: 'calc(50vw - 15rem)'
    }

    const notificationsBars = this.state.notifications.map( 
      (notification, ix) => (
        <NotificationBar 
          notification={notification} 
          onDismiss={this._notificationDismissed.bind(this)} 
          key={`notification${ix}`}
          onMouseEnter={this._handleMouseEnter.bind(this, notification)}
          onMouseOut={this._handleMouseOut.bind(this, notification)}
          onClick={this._handleMouseClick.bind(this, notification)}
        />
      )
    )

    return (
      <NotificationContext.Provider value={this.state}>
        {this.props.children}
        <div style={styles}>
          { this.state.notifications.length ?
            <Stack tokens={{childrenGap: 10}}>
              {notificationsBars}
            </Stack>
            : null
          }
        </div>
      </NotificationContext.Provider>
    )
  }

  _showNotification({message, actions, timeout, type}) {
    this.setState(state => ({
      notifications: [
        ...state.notifications, 
        {
          timeout, 
          type,
          message,
          actions,
          progress: 0,
          isHovered: false,
          id: UUID(),
        }
      ]
    }), this._maybeStartNotificationTimer.bind(this))
  }

  _showError({message, actions, timeout}) {
    this._showNotification({
      timeout: timeout || null, 
      type: MessageBarType.error, 
      message,
      actions,
    })
  }

  _showWarning({message, actions, timeout}) {
    this._showNotification({
      timeout: timeout || null, 
      type: MessageBarType.warning, 
      message,
      actions,
    })
  }

  _showInfo({message, actions, timeout}) {
    this._showNotification({
      timeout: timeout || (actions ? null : 5000), 
      type: MessageBarType.info, 
      message,
      actions,
    })
  }

  _showSuccess({message, actions, timeout}) {
    this._showNotification({
      timeout: timeout || (actions ? null : 5000), 
      type: MessageBarType.success, 
      message,
      actions,
    })
  }

  _handleMouseClick(notification) {
    this.setState(state => ({
      notifications: state.notifications.map( 
        n => 
          n.id === notification.id ? 
          ({...n, timeout: null}) : 
          ({...n})
      )
    }))
  }

  _handleMouseEnter(notification) {
    this.setState(state => ({
      notifications: state.notifications.map( 
        n => 
          n.id === notification.id ? 
          ({...n, isHovered: true}) : 
          ({...n})
      )
    }))
  }

  _handleMouseOut(notification) {
    this.setState(state => ({
      notifications: state.notifications.map( 
        n => 
          n.id === notification.id ? 
          ({...n, isHovered: false}) : 
          ({...n})
      )
    }))
  }

  _timerTick() {
    this.setState(state => ({
      notifications: state.notifications.map(
        notification => {
          const progress = notification.progress + (notification.isHovered ? 0 : NOTIFICATION_TIMER_INTERVAL)
          return ({
            ...notification,
            progress
          })
        }
      )
    }), this._afterTimerTick.bind(this))
  }

  _afterTimerTick() {
    this.setState(state => ({
      notifications: state.notifications.filter( 
        notification => !Number.isFinite(notification.timeout) || notification.progress <= notification.timeout
      )
    }), this._afterNotificationDismissed.bind(this))
  }

  _afterNotificationDismissed() {
    if ((!this.state.notifications.length || 
      !this.state.notifications.some(
        notification => Number.isFinite(notification.timeout)
      )
    ) && this.notificationTimer) {
      clearInterval(this.notificationTimer)
      this.notificationTimer = null
    }
  }

  _maybeStartNotificationTimer() {
    if (
      this.state.notifications.length && 
      this.state.notifications.some( 
        notification => Number.isFinite(notification.timeout)
      ) && !this.notificationTimer
    ) {
      this.notificationTimer = setInterval(this._timerTick.bind(this), NOTIFICATION_TIMER_INTERVAL)
    }
  }

  _notificationDismissed(notification) {
    this.setState(state => ({
      notifications: state.notifications.filter(
        n => n.id !== notification.id
      )
    }), this._afterNotificationDismissed.bind(this))
  }
}